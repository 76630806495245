/* eslint no-console:0 */
const Rails = require('@rails/ujs');

Rails.start();

require('stylesheets/ecs/application.sass');

require('shared/components/accordion');
require('shared/components/burger_menu');
require('shared/components/dropdown');
require('shared/components/dropdown_advanced');
require('shared/components/modal');
require('shared/klaro-config');

require('ecs/date_input_select');
require('ecs/loader');
require('ecs/login');
require('ecs/password_field');
require('ecs/file-downloader');

require('ecgcc/site_select');
